export default {
  statement: {
    rows: [{
      id: 1,
      store_id: 1,
      store_name: 'LYF',
      qty_order: 9,
      qty_wait_payment: 0,
      qty_wait_data: 55,
      qty_cancelled: 12,
      total: 76,
      amount: 17220
    }, {
      id: 2,
      store_id: 2,
      store_name: 'FNF',
      qty_order: 0,
      qty_wait_payment: 0,
      qty_wait_data: 4,
      qty_cancelled: 2,
      total: 6,
      amount: ''
    }, {
      id: 3,
      store_id: 3,
      store_name: 'AFR',
      qty_order: 0,
      qty_wait_payment: 0,
      qty_wait_data: 2,
      qty_cancelled: 1,
      total: 3,
      amount: ''
    }, {
      id: 4,
      store_id: 4,
      store_name: 'WNW',
      qty_order: 48,
      qty_wait_payment: 1,
      qty_wait_data: 8,
      qty_cancelled: 8,
      total: 85,
      amount: 82759
    }, {
      id: 5,
      store_id: 5,
      store_name: 'WRE',
      qty_order: 18,
      qty_wait_payment: 0,
      qty_wait_data: 4,
      qty_cancelled: 15,
      total: 37,
      amount: 28750
    }, {
      id: 6,
      store_id: 6,
      store_name: 'LEW',
      qty_order: 9,
      qty_wait_payment: 2,
      qty_wait_data: 1,
      qty_cancelled: 5,
      total: 17,
      amount: 27420
    }],
    paging: {
      rowTotal: 1
    },
    order: {
      qty: 4,
      total_order: 121,
      delivery_today: 99,
      forwards_order: 22
    }
  }
}
