<template>
  <div>
    <div>
      <div class="card custom-card">
        <div class="card-body">
          
          <div class="row">
            <div class="col-md-4">
              <div class="mb-4">
                <label class="form-label">วันที่</label>
                <date-picker :format="'DD/MM/YYYY'" v-model="item.date" class="w-100" input-class="form-control" placeholder="วันที่" :append-to-body="false" :popup-style="{left: 0}"></date-picker>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-4">
                <label class="form-label">ช่วงเวลา</label>
                <select class="form-control" v-model="item.time">
                  <option v-for="(data) in times" :key="`time${data.id}`" :value="data.id"> {{data.text}}</option>
                </select>
              </div>
            </div>
            <div class="col-12">
              <b-button type="button" variant="primary" class="style_btn" @click="clickAgree()"> ตกลง</b-button>
            </div>
          </div>
          <div class="table-responsive padding_top_20">
            <table class="table align-middle table-bordered">
              <thead>
                <tr class="text-center" >
                  <th>ชื่อร้านค้า</th>
                  <th>ออเดอร์</th>
                  <th>รอชำระ</th>
                  <th>รอข้อมูล</th>
                  <th>Cancelled</th>
                  <th>ผลรวมทั้งหมด</th>
                  <th>ยอดเงินเข้า</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in item.rows" :key="`statement_list${item.id}`">
                  <td class="text-center">{{item.name}}</td>
                  <td class="text-center">{{item.total_order}}</td>
                  <td class="text-center">{{item.total_wait_payment}}</td>
                  <td class="text-center">{{item.total_order_wait}}</td>
                  <td class="text-center">{{item.total_cancel}}</td>
                  <td class="text-center">{{item.total}}</td>
                  <td class="text-center">{{item.total_revenue| number-decimal-format}}</td>
                </tr>
                <tr v-if="item.rows.length === 0" class="text-center">
                  <td colspan="7">ไม่มีข้อมูล</td>
                </tr>
              </tbody>
              <tfoot v-if="item.rows.length > 0">
                <tr>
                  <th class="text-right">ผลรวม</th>
                  <th class="text-center">{{sum('total_order')}}</th>
                  <th class="text-center">{{sum('total_wait_payment')}}</th>
                  <th class="text-center">{{sum('total_order_wait')}}</th>
                  <th class="text-center">{{sum('total_cancel')}}</th>
                  <th class="text-right">{{funcs.toDecimalFormat(sum('total'))}}</th>
                  <th class="text-right">{{funcs.toDecimalFormat(sum('total_revenue'))}}</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <div class="row padding_top_20">
        <div  class="col-lg-1 col-md-2 col-3"
              style="margin-top: 30px;">
          <select class="form-control mb-2"
                  id="pageLimit"
                  style="width: 80px;"
                  v-model="paging.limit" v-on:change="changeLimit()">
            <option v-for="(data) in paging.pages"
                    :key="data"
                    :value="data">
              {{data}}
            </option>
          </select>
        </div>
        <div class="col-lg-11">
          <div class="row g-3"  v-if="paging.rowTotal > 0">
            <div  class="col-xl-11 col-lg-10 mb-2 text-right"
                  style="margin-top: 30px;">
              <label  class="form-label"
                      style="margin: 8px; font-size: 12px;
                      font-family: Sarabun-Light;
                      color: #818181;">
                แสดงรายการที่ <strong>{{((paging.page-1)*paging.limit)+1}} ถึง {{(paging.page) * paging.limit}}</strong> จาก <strong>{{paging.rowTotal | numberIntFormat}} รายการ </strong>
              </label>
              <paginate v-model="paging.page"
                        :first-last-button="true"
                        :page-count="paging.pageTotal"
                        :page-range="5"
                        :margin-pages="2"
                        :prev-text="'&lt;'"
                        :next-text="'&gt;'"
                        :first-button-text="'&verbar;&lt;'"
                        :last-button-text="'&gt;&verbar;'"
                        :click-handler="changePage"
                        :container-class="'pagination pagination-sm float-right m-auto paging_ul'"
                        :page-class="'page-link'"
                        :prev-class="'page-link prev_paging'"
                        :next-class="'page-link next_paging'">
              </paginate>
            </div>
            <div class="col-xl-1 col-lg-2 padding_left_none">
              <label  class="form-label"
                      style="font-size: 12px;
                      font-family: Sarabun-Light;">
                Go to page
              </label>
              <Select2 v-model="paging.page" :options="paging.pageSizes" @change="changePage(paging.page)"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
    </div>
  </div>
</template>
<script>
  import config from '../../../util/config';
  import DateUtils from "../../../util/dateUtils";
  import * as scripts from '../../../util/scripts';
  import mock from '../mock/statement';
  import moment from 'moment';
  export default {
    name: 'store-view-page-store',
    data() {
      return {
        item: {
          date: moment().toDate(),
          time: 1,
          rows: [],
          order: {
            qty_order: '',
            total_order: '',
            delivery_today: '',
            forwards_order: ''
          }
        },
        paging: {
          page: 1,
          limit: config.pagination.limit,
          pages: config.pagination.pages,
          rowTotal: 0,
          pageTotal: 0,
          pageSizes: []
        },
        times: [{
          id: 1,text: '12.00'
        }, {
          id: 2,text: '15.00'
        }, {
          id: 3,text: '18.00'
        }, {
          id: 4,text: '21.00'
        }, {
          id: 5,text: '22.00'
        }],
        funcs: scripts.funcs,
        mock: mock
      };
    },
    methods: {
      async clickAgree () {
        let DateDate = this.item.date ? DateUtils.dateFormat(this.item.date, "YYYY-MM-DD") : '';
        //let DateTime = this.item.time ? DateUtils.dateFormat(this.item.time, "HH:mm:ss") : '';
        const param = {date : DateDate,period : this.item.time};
        const result = await this.HttpServices.postData(`/ab_statement/overview`,param);
        this.item.rows = result.data;

       /*
        if (result && result.length > 0) {
        }
        */
          
       // const result = this.HttpServices.getMasterData(`/statement/${url}${params}`);
          

        //let url = "/overview";
   
        //this.item.rows = this.mock.statement.rows;
        //this.item.order = this.mock.statement.order;
      },
      sum (prop) {
        let result = 0;
        result = this.item.rows.reduce((sum, row) => {
          if (row[`${prop}`]) {
            return sum + row[`${prop}`];
          } else {
            return sum + 0;
          }
        }, 0);
        console.log('result ', result, prop)
        return result;
      }
    }
  };
</script>
<style scope>
  .checkbox_style > .custom-control {
    left: 12px;
  }
</style>
